<template>
  <el-dialog
    :visible="visible"
    title="门店方案配置"
    class="combo-dialog"
    @opened="afterOpen"
    @close="onClose"
    width="1400px"
  >
    <div class="header-search">
      <el-form
        ref="form"
        :model="searchData"
        label-width="80px"
        inline
        class="combo-setting-form"
      >
        <el-form-item label="ID">
          <el-input
            v-model="searchData.id"
            size="small"
            placeholder="请输入"
            class="search-form-item"
          />
        </el-form-item>
        <el-form-item label="方案名称">
          <el-input
            v-model="searchData.comboName"
            size="small"
            placeholder="请输入"
            class="search-form-item"
          />
        </el-form-item>
        <el-form-item label="方案等级">
          <el-select
            v-model="searchData.comboGrade"
            placeholder="请选择"
            size="small"
            clearable
            class="search-form-item"
          >
            <el-option
              v-for="item in dict.ComboGradeList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="总期数">
          <el-input-number
            v-model="searchData.billNum"
            size="small"
            placeholder="请输入"
            :controls="false"
            :min="1"
            class="search-form-item"
          />
        </el-form-item>
        <el-form-item label="成单奖励">
          <el-select
            v-model="searchData.rewardRate"
            placeholder="请选择"
            size="small"
            clearable
            class="search-form-item"
          >
            <el-option label="是" :value="true" />
            <el-option label="否" :value="false" />
          </el-select>
        </el-form-item>
        <el-form-item label=" ">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="search"
            >搜索</el-button
          >
          <el-button
            type="info"
            size="small"
            icon="el-icon-refresh"
            @click="reset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column
        prop="checked"
        width="55"
        :render-header="renderSelectionHeader"
      >
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.isSelect" /> </template
      ></el-table-column>
      <el-table-column prop="id" label="ID" width="55" />
      <el-table-column prop="comboName" label="方案名称" :min-width="150" />
      <el-table-column prop="comboTypeName" label="方案类型" />
      <el-table-column prop="comboGrade" label="方案等级">
        <template slot-scope="scope">
          <span>{{ scope.row.comboGrade }}级</span>
        </template>
      </el-table-column>
      <el-table-column prop="billNum" label="总期数">
        <template slot-scope="scope">
          <span>{{ scope.row.billNum }}期</span>
        </template>
      </el-table-column>
      <el-table-column prop="prepayBillNum" label="首付期数">
        <template slot-scope="scope">
          <span>{{ scope.row.prepayBillNum }}期</span>
        </template>
      </el-table-column>
      <el-table-column prop="rewardRate" label="成单奖励比例">
        <template slot-scope="scope">
          <span>{{ scope.row.rewardRate }}%</span>
          <i
            class="el-icon-edit"
            style="cursor: pointer; color: #409eff; margin-left: 10px"
            @click="editReward(scope.row)"
          ></i>
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="onSubmit">保 存</el-button>
    </span>
    <el-dialog
      :visible.sync="editRewardProps.visible"
      title="成单奖励"
      append-to-body
      width="600px"
    >
      <el-form
        label-width="150px"
        ref="rewardForm"
        :rules="editRewardRules"
        :model="editRewardProps"
      >
        <el-form-item label="当前成单奖励比例：">
          {{ editRewardProps.rewardRateOld }}%
        </el-form-item>
        <el-form-item label="成单奖励比例：" prop="rewardRate">
          <el-input-number
            v-model="editRewardProps.rewardRate"
            size="small"
            placeholder="请输入"
            :controls="false"
            :precision="0"
            class="search-form-item"
          />
          %
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editRewardProps.visible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmitReward">保 存</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
import {
  ComboGradeList,
  ComboTypeList,
  EnumComboType,
} from "@/enum/dict/index.js";
import { getComboConfig, saveComboConfig } from "./api.js";

export default {
  name: "ComboSetting",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    id: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  data() {
    return {
      searchData: {
        id: undefined,
        comboName: "",
        comboGrade: "",
        billNum: undefined,
        comboType: EnumComboType.CUSTOM.toString(),
        rewardRate: undefined,
      },
      tableData: [],
      comboList: [],
      bindComboList: [],
      dict: {
        ComboGradeList,
        ComboTypeList,
      },
      editRewardProps: {
        visible: false,
        comboId: undefined,
        rewardRateOld: undefined,
        rewardRate: undefined,
      },
      editRewardRules: {
        rewardRate: [
          { required: true, type: "number", message: "请输入成单奖励比例", },
          { pattern: /^(10|[0-9])$/, message: "成单奖励填写超限，请重新录入。", trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    renderSelectionHeader() {
      const checked = this.tableData.every((item) => item.isSelect);

      return <el-checkbox value={checked} onChange={this.onSelectChange} />;
    },
    onSelectChange(checked) {
      this.tableData.forEach((item) => {
        item.isSelect = checked;
      });
    },
    async afterOpen() {
      const res = await getComboConfig({
        storeId: this.id,
      });

      this.comboList = res.comboList;
      this.bindComboList = res.bindComboList;

      this.reset();
    },
    onClose() {
      this.$emit("update:visible", false);
    },
    async onSubmit() {
      await saveComboConfig({
        storeId: this.id,
        bindComboList: this.comboList
          .filter((combo) => {
            return combo.comboType === EnumComboType.CUSTOM && combo.isSelect;
          })
          .map((combo) => {
            return {
              comboId: combo.id,
              rewardRate: combo.rewardRate,
            };
          }),
      });

      this.$message({
        type: "success",
        message: "操作成功",
      });

      this.onClose();

      this.$emit("refreshList");
    },
    search() {
      this.buildDableData();
    },
    reset() {
      this.$set(this, "searchData", this.$options.data().searchData);

      this.buildDableData();
    },
    buildDableData() {
      const filterKey = (list) => {
        return list.filter((key) => {
          return ![null, undefined, ""].includes(this.searchData[key]);
        });
      };

      const equalList = filterKey(["id", "comboGrade", "billNum", "comboType"]);

      const containsList = filterKey(["comboName"]);

      const data = this.comboList.filter((combo) => {
        const someEqual = equalList.every(
          (key) => this.searchData[key] == combo[key],
        );

        const someContains = containsList.every((key) =>
          combo[key].includes(this.searchData[key]),
        );

        let rewardRate = true;

        if (this.searchData.rewardRate === true) {
          rewardRate = combo.rewardRate > 0;
        }

        if (this.searchData.rewardRate === false) {
          rewardRate = combo.rewardRate === 0;
        }

        return someEqual && someContains & rewardRate;
      });

      this.tableData = data;
    },
    editReward(row) {
      console.log(row);
      this.editRewardProps.visible = true;
      this.editRewardProps.comboId = row.id;
      this.editRewardProps.rewardRateOld = row.rewardRate;

      this.$refs.rewardForm?.resetFields();
    },
    onSubmitReward() {
      this.$refs.rewardForm.validate((valid) => {
        if (!valid) {
          return;
        }

        this.editRewardProps.visible = false;
        this.comboList = this.comboList.map((combo) => {
          if (combo.id === this.editRewardProps.comboId) {
            combo.rewardRate = this.editRewardProps.rewardRate;
          }

          return combo;
        });

        this.buildDableData();

        console.log(this.tableData);
      });
    },
  },
};
</script>
<style scoped lang="scss">
.header-search {
  .search-form-item {
    width: 180px;

    /deep/ {
      input {
        text-align: left;
      }
    }
  }
}
</style>
