import { http } from "@/http/axios.js";
import axios from "axios";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { showScreenLoading, hideScreenLoading } from "@/http/loading.js";
import { Message } from "element-ui";
import { dateFormat } from "@/utils/index.js";
// list
export function getListAPI(params) {
  return http({
    url: "/admin/store/page",
    method: "get",
    params,
  });
}
// delete
export function deleteStoreAPI(id) {
  return http({
    url: "/admin/store/delete",
    method: "post",
    data: { id },
  });
}
// add
export function addStoreAPI(data) {
  return http({
    url: "/admin/store/add",
    method: "post",
    data,
  });
}
// edit
export function editStoreAPI(data) {
  return http({
    url: "/admin/store/edit",
    method: "post",
    data,
  });
}
// detail
export function getStoreDetailAPI(id) {
  return http({
    url: "/admin/store/detail",
    method: "get",
    params: { id },
  });
}
// status
export function changeStatusAPI(data) {
  return http({
    url: "/admin/store/status",
    method: "post",
    data,
  });
}
export function changeEnableLoginAPI(data) {
  return http({
    url: "/admin/store/loginStatus",
    method: "post",
    data,
  });
}
// status
export function changeWapStatusAPI(data) {
  return http({
    url: "/admin/store/wap",
    method: "post",
    data,
  });
}
// 二维码
export function getQrcodeAPI(id) {
  return http({
    url: "/admin/store/qrImage",
    method: "post",
    data: { id },
  });
}
// 物料
export function getStoreMaterialAPI(id) {
  return http({
    url: "/admin/store/materialImage",
    method: "post",
    data: { id },
  });
}
// 支付宝物料
export function getStoreAlipayMaterialAPI(id) {
  return http({
    url: "/admin/store/alipayMaterialImage",
    method: "post",
    data: { id },
  });
}
export function downloadFile(pdfUrl, name) {
  axios({
    url: pdfUrl,
    method: "GET",
    responseType: "blob", // 必须指定为blob类型才能下载
  }).then((res) => {
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${name}.png`);
    document.body.appendChild(link);
    link.click();
  });
}
// 导出
export function exportAPI(data) {
  showScreenLoading();
  axios({
    method: "post",
    url: "/api/admin/store/export",
    responseType: "blob",
    data,
    headers: {
      Authorization: getToken() || "",
    },
  })
    .then(async (res) => {
      hideScreenLoading();
      let data = res.data;
      // if (!data || data.type !== 'application/vnd.ms-excel') {
      //   let response = await new Response(data).text()
      //   response = JSON.parse(response)
      //   Message.error(response?.message || '导出失败')
      //   return
      // }
      let url = window.URL.createObjectURL(new Blob([data]));
      let a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // let filename = res?.headers['content-disposition'].split('filename=').pop() || '财务明细.xlsx'
      let filename = `门店列表${dateFormat()}.xlsx`;
      a.setAttribute("download", decodeURIComponent(filename));
      document.body.appendChild(a);
      a.click(); //执行下载
      window.URL.revokeObjectURL(a.href); //释放url
      document.body.removeChild(a); //释放标签
    })
    .catch((error) => {
      hideScreenLoading();
      console.log(error);
    });
}

export function editStoreGrade(data) {
  return http({
    url: "/admin/store/grade/edit",
    method: "post",
    data,
  });
}
