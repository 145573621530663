import { http } from "@/http/axios.js";

// 方案列表
export function getComboList(params) {
  return http({
    url: "/admin/store/combo/page",
    method: "get",
    params,
  });
}

// 方案详情
export function getComboDetail(params) {
  return http({
    url: "/admin/store/combo/detail",
    method: "get",
    params,
  });
}

// 新增方案
export function createCombo(data) {
  return http({
    url: "/admin/store/combo/add",
    method: "post",
    data,
  });
}

// 编辑方案
export function editCombo(data) {
  return http({
    url: "/admin/store/combo/edit",
    method: "post",
    data,
  });
}

// 编辑状态
export function editComboStatus(data) {
  return http({
    url: "/admin/store/combo/status/edit",
    method: "post",
    data,
  });
}

// 删除方案
export function deleteCombo(data) {
  return http({
    url: "/admin/store/combo/delete",
    method: "post",
    data,
  });
}

// 获取门店套餐列表
export function getComboGradeList(params) {
  return http({
    url: "/admin/store/combo/gradeList",
    method: "get",
    params,
  });
}

// 获取门店方案配置信息
export function getComboConfig(params) {
  return http({
    url: "/admin/store/comboConfig/info",
    method: "get",
    params,
  });
}

// 保存门店方案配置信息
export function saveComboConfig(data) {
  return http({
    url: "/admin/store/comboConfig/save",
    method: "post",
    data,
  });
}
