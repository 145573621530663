<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="门店名称">
          <el-input
            v-model="searchData.storeName"
            size="small"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="门店类型">
          <el-select
            v-model="searchData.storeType"
            placeholder="请选择"
            size="small"
            clearable
          >
            <el-option
              v-for="item in dict_store_type"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="负责人手机号">
          <el-input
            v-model="searchData.legalMobile"
            size="small"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="创建时间" class="item">
          <el-date-picker
            style="width: 280px"
            v-model="searchData.timeSlot"
            size="small"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="归属">
          <SelectKf v-model="searchData.businessUserId"></SelectKf>
        </el-form-item>
        <el-form-item label="结算方式">
          <el-select
            v-model="searchData.settleType"
            placeholder="请选择"
            size="small"
            clearable
          >
            <el-option
              v-for="item in paymentMethod"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="成单奖励">
          <el-select
            v-model="searchData.enableRewardCombo"
            placeholder="请选择"
            size="small"
            clearable
          >
            <el-option label="是" :value="true"></el-option>
            <el-option label="否" :value="false"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=" ">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="getList"
            >搜索</el-button
          >
          <el-button
            type="info"
            size="small"
            icon="el-icon-refresh"
            @click="reset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <div class="handle-btn">
      <el-button
        v-checkbtn="isShowBtn(AUTH_BTN.store_list_add)"
        style="margin-bottom: 20px"
        type="primary"
        icon="el-icon-plus"
        size="small"
        @click="$router.push({ name: 'StoreAdd' })"
        >新增
      </el-button>
      <el-button
        v-checkbtn="isShowBtn(AUTH_BTN.store_list_export)"
        size="small"
        icon="el-icon-download"
        type="primary"
        @click="exportExcel"
        >导出</el-button
      >
    </div>
    <tp-table
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="pageSize"
    />
    <!-- 物料弹窗 -->
    <el-dialog
      :title="`门店名称：${storeName}`"
      :visible.sync="dialogVisibleMaterial"
      width="700px"
    >
      <el-divider content-position="center">h5物料</el-divider>
      <div class="code-img">
        <el-image style="width: 200px" fit="fill" :src="materialUrl"></el-image>
        <el-button
          style="margin-top: 10px"
          type="primary"
          size="small"
          @click="downloadMaterial"
          >下载</el-button
        >
      </div>
    </el-dialog>
    <!-- 支付宝物料弹窗 -->
    <el-dialog
      :title="`门店名称：${storeName}`"
      :visible.sync="dialogVisibleAlipayMaterial"
      width="700px"
    >
      <el-divider content-position="center">支付宝物料</el-divider>
      <div class="code-img">
        <el-image
          style="width: 200px"
          fit="fill"
          :src="alipayMaterialUrl"
        ></el-image>
        <el-button
          style="margin-top: 10px"
          type="primary"
          size="small"
          @click="downloadAlipayMaterialImage"
          >下载</el-button
        >
      </div>
    </el-dialog>
    <!-- 详情弹窗 -->
    <el-drawer
      title="门店详情"
      :visible.sync="show_detail"
      direction="rtl"
      size="50%"
    >
      <div class="store-detail">
        <el-descriptions direction="vertical" :column="3" border>
          <el-descriptions-item label="门店名称">{{
            detail.storeName
          }}</el-descriptions-item>
          <el-descriptions-item label="门店地址">{{
            detail.addressDetail
          }}</el-descriptions-item>
          <el-descriptions-item label="状态">
            <el-tag :type="detail.status ? 'primary' : 'danger'" size="small">{{
              detail.status ? "启用" : "禁用"
            }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="负责人名称">{{
            detail.legalName
          }}</el-descriptions-item>
          <el-descriptions-item label="负责人手机号">
            {{ detail.legalMobile }}</el-descriptions-item
          >
          <el-descriptions-item label="门店归属">
            {{ businessUserName }}</el-descriptions-item
          >
          <el-descriptions-item label="监管锁费">
            {{ detail.lockAmount }}</el-descriptions-item
          >
          <el-descriptions-item label="保险金系数">
            <span>{{ detail.insuranceRate }}</span>
            <span style="color: #f56c6c">（注：保险金=零售价*保险金系数）</span>
          </el-descriptions-item>
          <el-descriptions-item label="门店标签">
            <el-tag
              style="margin-right: 5px"
              type="primary"
              size="mini"
              v-for="(item, i) in detail.storeTagList"
              :key="i"
              >{{ item }}</el-tag
            >
            <span v-if="detail.storeTagList && detail.storeTagList.length == 0"
              >无</span
            >
          </el-descriptions-item>
          <el-descriptions-item label="开户行">
            {{ detail.bankName || "未设置" }}</el-descriptions-item
          >
          <el-descriptions-item label="银行卡号">
            {{ detail.bankCardNo || "未设置" }}</el-descriptions-item
          >
          <el-descriptions-item label=""> </el-descriptions-item>
          <el-descriptions-item label="门店结算方式">
            {{
              detail.settleType === 1 ? "线下打款" : "支付宝自动结算"
            }}</el-descriptions-item
          >
          <el-descriptions-item label="收款账户信息">
            {{ countInfo }}</el-descriptions-item
          >
        </el-descriptions>
        <el-tabs v-model="activeName">
          <el-tab-pane label="营业执照" name="first">
            <el-image
              style="width: 400px; height: 300px"
              :src="detail.licenseImageUrl"
              :preview-src-list="[detail.licenseImageUrl]"
              fit="cover"
            ></el-image>
          </el-tab-pane>
          <el-tab-pane label="门头照片" name="second">
            <el-image
              style="width: 300px; height: 200px; margin-right: 10px"
              v-for="(item, i) in detail.facadeImageUrlList"
              :key="i"
              :src="item"
              :preview-src-list="[item]"
              fit="cover"
            ></el-image>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-drawer>
    <ComboSetting
      :visible.sync="comboSettingProps.visible"
      :id="comboSettingProps.id"
      @refreshList="getList"
    />
    <StoreGrade
      :visible.sync="storeGradeProps.visible"
      :id="storeGradeProps.id"
      :grade="storeGradeProps.grade"
      @refreshList="getList"
    />
  </div>
</template>

<script>
import { authBtnMixin } from "@/mixins/authBtnMixin";

import {
  exportAPI,
  getListAPI,
  deleteStoreAPI,
  changeStatusAPI,
  changeWapStatusAPI,
  getQrcodeAPI,
  getStoreMaterialAPI,
  getStoreAlipayMaterialAPI,
  downloadFile,
  getStoreDetailAPI,
  changeEnableLoginAPI,
} from "./api";
import SelectKf from "@/views/components/select-kf.vue";
import ComboSetting from "@/views/store/combo/combo-setting.vue";
import StoreGrade from "./store-grade.vue";
import { dict_store_type } from "@/enum/dict/index.js";

const columns = [
  {
    label: "门店名称",
    prop: "storeName",
    minWidth: "200",
    customRender(h, row) {
      return (
        <p>
          {row["storeName"]}
          {row["storeTagList"].map((item) => {
            return (
              <el-tag type="danger" size="mini" style="margin-left: 5px;">
                {item}
              </el-tag>
            );
          })}
        </p>
      );
    },
  },
  {
    label: "门店等级",
    prop: "storeGrade",
    minWidth: "100",
    customRender(h, row) {
      return <p>{row["storeGrade"] ? `${row["storeGrade"]}级` : "-"}</p>;
    },
  },
  {
    label: "成单奖励方案数",
    prop: "rewardComboNum",
    minWidth: "100",
  },
  {
    label: "门店类型",
    prop: "storeType",
    minWidth: "100",
    customRender(h, row) {
      return <p>{row["storeTypeName"]}</p>;
    },
  },
  {
    label: "门店地址",
    prop: "address",
    minWidth: "200",
    customRender(h, row) {
      return <p>{row["address"]}</p>;
    },
  },
  {
    label: "负责人名称",
    prop: "legalName",
    minWidth: "100",
    customRender(h, row) {
      return <p>{row["legalName"]}</p>;
    },
  },
  {
    label: "负责人手机号",
    prop: "legalMobile",
    minWidth: "120",
    customRender(h, row) {
      return <p>{row["legalMobile"]}</p>;
    },
  },
  {
    label: "结算方式",
    prop: "settleTypeName",
    minWidth: "130",
    customRender(h, row) {
      return <p>{row["settleTypeName"]}</p>;
    },
  },
  // {
  //   label: '开户行',
  //   prop: 'bankName',
  //   minWidth: '250',
  //   customRender(h, row) {
  //     return (
  //       <div>
  //         <div>开户行：{row['bankName']}</div>
  //         <div>银行卡号：{row['bankCardNo']}</div>
  //       </div>
  //     )
  //   }
  // },
  // {
  // 	label: "保险金系数",
  // 	prop: "insuranceRate",
  // 	minWidth: "100",
  // 	customRender(h, row) {
  // 		return <p>{row["insuranceRate"]}</p>;
  // 	},
  // },
  {
    label: "监管锁费",
    prop: "lockAmount",
    minWidth: "100",
    customRender(h, row) {
      return <p>{row["lockAmount"]}</p>;
    },
  },
  {
    label: "添加时间",
    prop: "createTime",
    minWidth: "170",
    customRender(h, row) {
      return <p>{row["createTime"]}</p>;
    },
  },
  {
    label: "状态",
    prop: "status",
    minWidth: "120",
    customRender(h, row) {
      return (
        <div>
          <el-switch
            disabled={!this.isShowBtn(this.AUTH_BTN.store_list_status)}
            class="switch"
            onChange={() => this.changeStatus(row)}
            v-model={row["status"]}
            active-value={true}
            inactive-value={false}
            active-text="启用"
            inactive-text="禁用"
          ></el-switch>
        </div>
      );
    },
  },
  {
    label: "后台登录",
    prop: "status",
    minWidth: "120",
    customRender(h, row) {
      return (
        <div>
          <el-switch
            class="switch"
            onChange={() => this.changeEnableLogin(row)}
            v-model={row["enableLogin"]}
            active-value={true}
            inactive-value={false}
            active-text="启用"
            inactive-text="禁用"
          ></el-switch>
        </div>
      );
    },
  },
  {
    label: "门店归属",
    prop: "businessUserName",
    minWidth: "110",
    customRender(h, row) {
      return <p>{row["businessUserName"]}</p>;
    },
  },
  {
    label: "h5物料",
    prop: "enableWap",
    minWidth: "120",
    customRender(h, row) {
      return (
        <div>
          <el-switch
            disabled={!this.isShowBtn(this.AUTH_BTN.store_list_wap_status)}
            class="switch"
            onChange={() => this.changeWapStatus(row)}
            v-model={row["enableWap"]}
            active-value={true}
            inactive-value={false}
            active-text="启用"
            inactive-text="禁用"
          ></el-switch>
        </div>
      );
    },
  },
  {
    label: "操作",
    minWidth: "200",
    fixed: "right",
    customRender(h, row) {
      return (
        <div style="display: flex; flex-wrap: wrap; column-gap: 10px;">
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.store_list_detail)}
            underline={false}
            type="primary"
            onClick={() => this.handleDetail(row)}
          >
            详情
          </el-link>
          <el-link
            disabled={
              row["status"] || !this.isShowBtn(this.AUTH_BTN.store_list_edit)
            }
            underline={false}
            type="primary"
            onClick={() =>
              this.$router.push({
                name: "StoreEdit",
                params: { id: row["id"] },
              })
            }
          >
            编辑
          </el-link>
          <el-link
            disabled={
              row["status"] || !this.isShowBtn(this.AUTH_BTN.store_list_del)
            }
            underline={false}
            type="primary"
            onClick={() => this.handleDel(row)}
          >
            删除
          </el-link>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.store_list_copy)}
            underline={false}
            type="primary"
            onClick={() => this.copyLink(row["storeUrl"])}
          >
            复制链接
          </el-link>
          <el-link
            disabled={
              !this.isShowBtn(this.AUTH_BTN.store_list_wuliao) ||
              !row["enableWap"]
            }
            underline={false}
            type="primary"
            onClick={() => this.showMaterialImage(row)}
          >
            h5物料
          </el-link>
          <el-link
            disabled={
              !this.isShowBtn(this.AUTH_BTN.store_list_alipay_material) ||
              row["storeType"] != 1
            }
            underline={false}
            type="primary"
            onClick={() => this.showAlipayMaterialImage(row)}
          >
            支付宝物料
          </el-link>
          <el-link
            underline={false}
            type="primary"
            onClick={() => this.showStoreGradeDialog(row)}
            disabled={!this.isShowBtn(this.AUTH_BTN.store_list_grade_config)}
          >
            等级配置
          </el-link>
          <el-link
            underline={false}
            type="primary"
            onClick={() => this.showComboSettingDialog(row)}
            disabled={!this.isShowBtn(this.AUTH_BTN.store_list_combo_config)}
          >
            方案配置
          </el-link>
        </div>
      );
    },
  },
];
export default {
  name: "StoreList",
  mixins: [authBtnMixin],
  components: { SelectKf, ComboSetting, StoreGrade },
  data() {
    return {
      dialogVisibleMaterial: false,
      dialogVisibleAlipayMaterial: false,
      dialogVisible: false,
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        storeName: "",
        storeType: "",
        legalMobile: "",
        timeSlot: [],
        businessUserId: "",
        settleType: "",
        enableRewardCombo: "",
      },
      storeName: "",
      url: "",
      qrcodeUrl: "",
      materialUrl: "",
      alipayMaterialUrl: "",
      show_detail: false,
      detail: {},
      businessUserName: "",
      activeName: "first",
      dict_store_type,
      paymentMethod: [
        {
          label: "线下打款",
          value: 1,
        },
        {
          label: "支付宝自动结算",
          value: 2,
        },
      ],
      comboSettingProps: {
        visible: false,
        id: null,
      },
      storeGradeProps: {
        visible: false,
        id: null,
        grade: null,
      },
    };
  },
  provide() {
    return {
      context: this,
    };
  },
  watch: {
    currentPage() {
      this.getList();
    },
    pageSize() {
      this.getList();
    },
  },
  computed: {
    countInfo() {
      if (this.detail.settleType === 1) {
        return "";
      } else {
        return (
          "姓名：" +
          (this.detail.payeeIdName || "-") +
          "        支付宝账号：" +
          (this.detail.payeeAlipayAccount || "-")
        );
      }
    },
  },
  mounted() {
    this.getList();
  },

  methods: {
    showStoreGradeDialog(row) {
      this.storeGradeProps.visible = true;
      this.storeGradeProps.id = row.id;
      this.storeGradeProps.grade = row.storeGrade;
    },
    showComboSettingDialog(row) {
      this.comboSettingProps.visible = true;
      this.comboSettingProps.id = row.id;
    },
    // 详情
    async handleDetail({ id, businessUserName }) {
      this.detail = await getStoreDetailAPI(id);
      this.businessUserName = businessUserName;
      this.show_detail = true;
    },
    async getList() {
      const {
        storeName,
        storeType,
        legalMobile,
        timeSlot,
        businessUserId,
        settleType,
        enableRewardCombo,
      } = this.searchData;
      let params = {
        storeName,
        storeType,
        legalMobile,
        businessUserId,
        settleType,
        enableRewardCombo,
        page: this.currentPage,
        pageSize: this.pageSize,
      };
      if (timeSlot && timeSlot.length > 0) {
        params.leftCreateDate = timeSlot[0];
        params.rightCreateDate = timeSlot[1];
      }
      const res = await getListAPI(params);
      this.list = res.list || [];
      this.total = res.total || 0;
    },
    // 导出
    exportExcel() {
      const {
        storeName,
        storeType,
        legalMobile,
        timeSlot,
        businessUserId,
        settleType,
        enableRewardCombo,
      } = this.searchData;
      let params = {
        storeName,
        storeType,
        legalMobile,
        businessUserId,
        settleType,
        enableRewardCombo,
        page: this.currentPage,
        pageSize: this.pageSize,
      };
      if (timeSlot && timeSlot.length > 0) {
        params.leftCreateDate = timeSlot[0];
        params.rightCreateDate = timeSlot[1];
      }
      exportAPI(params);
    },
    // 删除
    handleDel({ id }) {
      this.$confirm("此操作将永久删除该门店, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteStoreAPI(id).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功!",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 启用/禁用
    changeStatus(row) {
      let { id, status } = row;
      let data = { id, status };
      let statusDesc = status ? "启用" : "禁用";

      this.$confirm(`此操作将${statusDesc}该门店, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          row.status = status;
          changeStatusAPI(data).then(() => {
            this.getList();
            this.$message.success("操作成功");
          });
        })
        .catch(() => {
          row.status = !status;
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    changeEnableLogin(row) {
      let { id, enableLogin } = row;
      let data = { id, enableLogin };
      let statusDesc = enableLogin ? "启用" : "禁用";

      this.$confirm(`此操作将${statusDesc}该门店登录, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          row.enableLogin = enableLogin;
          changeEnableLoginAPI(data).then(() => {
            this.getList();
            this.$message.success("操作成功");
          });
        })
        .catch(() => {
          row.enableLogin = !enableLogin;
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    changeWapStatus(row) {
      let { id, enableWap } = row;
      let data = { id, enableWap };
      let enableWapDesc = enableWap ? "启用" : "禁用";

      this.$confirm(`此操作将${enableWapDesc}h5物料，是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          row.enableWap = enableWap;
          changeWapStatusAPI(data).then(() => {
            this.getList();
            this.$message.success("操作成功");
          });
        })
        .catch(() => {
          row.enableWap = !enableWap;
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData);
      this.getList();
    },
    async showCode(row) {
      this.qrcodeUrl = "";
      const { storeName, id } = row;
      const res = await getQrcodeAPI(id);
      this.storeName = storeName;
      this.$nextTick(function () {
        this.qrcodeUrl = res.qrImageUrl;
        this.dialogVisible = true;
      });
    },
    async showMaterialImage(row) {
      this.materialUrl = "";
      const { storeName, id } = row;
      const res = await getStoreMaterialAPI(id);
      this.storeName = storeName;
      this.$nextTick(function () {
        this.materialUrl = res.materialImageUrl;
        this.dialogVisibleMaterial = true;
      });
    },
    downloadMaterial() {
      downloadFile(this.materialUrl, this.storeName);
    },
    async showAlipayMaterialImage(row) {
      this.alipayMaterialUrl = "";
      const { storeName, id } = row;
      const res = await getStoreAlipayMaterialAPI(id);
      this.storeName = storeName;
      this.$nextTick(function () {
        this.alipayMaterialUrl = res.alipayMaterialImageUrl;
        this.dialogVisibleAlipayMaterial = true;
      });
    },
    downloadAlipayMaterialImage() {
      downloadFile(this.alipayMaterialUrl, this.storeName);
    },
    copyLink(url) {
      this.$copyText(url).then(
        () => {
          this.$message.success("复制成功");
        },
        () => {
          this.$message.error("复制失败");
        },
      );
    },
    resetPw(id) {
      this.$set(this.resetPwData, "id", id);
      this.dialogVisiblePw = true;
    },
    savePw() {
      resetPwAPI(this.resetPwData).then(() => {
        this.dialogVisiblePw = false;
        this.$message.success("重置成功");
        this.getList();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .header-search /deep/ {
    .el-range-separator {
      padding: 0;
    }
  }
  .handle-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .el-divider--horizontal {
    margin: 0 0 20px;
  }

  .el-form {
    .el-form-item {
      margin-right: 35px;
    }
  }
  .tp-table {
    .el-table__fixed-right {
      height: 100% !important;
    }
  }
  .code-img {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .store-detail {
    padding: 20px;
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  .el-dialog__body {
    padding: 20px 20px 50px 20px;
  }
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
    margin-right: 10px;
  }

  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }

  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>
