<template>
  <el-dialog
    :visible="visible"
    title="门店等级配置"
    @opened="afterOpen"
    @close="onClose"
    width="600px"
  >
    <el-form ref="form" :model="form" label-width="120px">
      <el-form-item label="门店当前等级">
        {{ grade ? dict.StoreGradeToTextMap.get(grade) : "-" }}
      </el-form-item>
      <el-form-item label="调整为">
        <el-select
          v-model="form.comboGrade"
          placeholder="请选择"
          size="small"
          class="search-form-item"
        >
          <el-option
            v-for="item in dict.StoreGradeList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="onSubmit">保 存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  EnumStoreGrade,
  StoreGradeList,
  StoreGradeToTextMap,
} from "@/enum/dict/index.js";

import { editStoreGrade } from "./api.js";

export default {
  name: "StoreGrade",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: false,
      default: null,
    },
    grade: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      form: {
        comboGrade: EnumStoreGrade.S,
      },
      dict: {
        StoreGradeList,
        StoreGradeToTextMap,
      },
    };
  },
  methods: {
    onClose() {
      this.$emit("update:visible", false);
    },
    afterOpen() {
      this.$refs.form.resetFields();
    },
    async onSubmit() {
      await editStoreGrade({
        id: this.id,
        storeGrade: this.form.comboGrade,
      });

      this.$message({
        type: "success",
        message: "操作成功",
      });

      this.onClose();

      this.$emit("refreshList");
    },
  },
};
</script>
<style scoped lang="scss"></style>
